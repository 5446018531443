/**
Synapse - adaptive theme
.col-xs- <544px Extra small
.col-sm- ≥544px Small
.col-md- ≥768px Medium
.col-lg- ≥992px Large
.col-xl- ≥1200px Extra large
Gutter width	1.875rem / 30px (15px on each side of a column)
*/
#synapse-copy {
  padding: 10px 0 0 0;
  font-size: 14px;
}
footer {
  font-family: 'pf_din_text_cond_proregular';
  color: #333333;
  font-size: 17px;

  .footer-menu {
    flex-direction: row;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    ul {
      margin: 0 30px 0 0;
      padding: 0;
      flex-grow: 0;
    }
    @media (min-width: 768px) {
      ul {
        flex-grow: 1;
      }
    }
    li {
      list-style: none;
      padding: 2px 0;
      a {
        border-bottom: 1px solid;
        &:hover, 
        &:focus {
          text-decoration: none;
          border-bottom: 1px solid transparent;
          -webkit-transition: all 200ms linear;
          -moz-transition: all 200ms linear;
          -ms-transition: all 200ms linear;
          -o-transition: all 200ms linear;
          transition: all 200ms linear;
        }
      }
    }
  }

}
footer .copy {
  padding: 30px 0 20px 0;
  line-height: 20px;
  a {
    color: #333333;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
footer .synapse-link {
  font-style: italic;
  text-decoration: none;
}
footer .footer-logo img {
  max-width: 100%;
}
footer .synapse {
  padding-top: 15px;
  text-align: right;
  a {
    color: #3e2c24;
    font-size: 16px;
  }
}
footer .synapse img {
  position: relative;
  top: -7px;
}
